import React, { useState, forwardRef, useEffect, useMemo } from 'react'

import AutoSuggest, { RenderSuggestionsContainerParams } from 'react-autosuggest'
import styled from 'styled-components'
const match = require('autosuggest-highlight/match')
const parse = require('autosuggest-highlight/parse')
import ValidationIndicator from '../ValidationIndicator'
import {
  getSuggestions, suggestionRule,
  getIsPayrollDeductionRequired
} from './suggestSource'
import { DropdownFooter, DropdownFooterContent } from './Footer'
import color from '../color-css/color'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'
import MarginWrapper from '../Commons/MarginWrapper'
import { ScrollBar } from '../ScrollBarComponent'
import { useSelector } from 'react-redux'
import { RootState } from 'store/'
import { STATE_FROM_SUBURB } from '../FormHighOrder/types/YourWorkField'

export type Props = {
  widthSize?: string
  onSelectedItemProp?: (isSuccess: boolean) => void
  isVisible?: (isVisible: boolean) => void
  inputFromProps?: string
  isValidatedFromProp?: boolean
  isOpenSuggestionList?: boolean
  handleData?: (value: string, isValid: boolean) => void
  suggestionSelectedOrTyped?: (isSelected: string) => void
  handlePayrollNumberRequire?: (isRequired: boolean) => void
  handlePayrollDeductionRequire?: (isRequired: boolean) => void
  isAvailableToInteract?: boolean
}

interface EmpAutoSuggestComponent {
  widthSize?: string
  onHoverInputSuccess?: boolean
  isVisible?: (isVisible: boolean) => boolean
  pointerEvent?: boolean
  isOpenSuggestionList?: boolean
}
const Input = styled.input`
  -webkit-appearance: none;
`

const StyledAutosuggestWrapper = styled(MarginWrapper) <EmpAutoSuggestComponent>`
  border: 1px solid ${color.white};
  outline: none;
  font-family: museo-sans, sans-serif;
  margin-bottom: 0.625rem;
& .react-autosuggest__container {
  position: relative;
 }

& .react-autosuggest__input {
  font-family: museo-sans, sans-serif;
  @media screen and (max-width: 690px) {
    padding: 0.8125rem 1.625rem 0.75rem 0.9375rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  width: ${props => props.widthSize ? props.widthSize : '34rem'};
  height: 1.1875rem;
  background-color: ${props => props.onHoverInputSuccess === true ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid ${color.white};
  outline : none;
  color: ${props => props.onHoverInputSuccess === true ? color.blue_17_percent : color.grey_29_percent};
  font-size: 1rem;
  padding-left: 1.5625rem;
  border-radius: 0.625rem;
  padding: 0.75rem 5.3125rem 0.6875rem 1.5rem;
  cursor: pointer;
  & ::placeholder,
  & ::-webkit-input-placeholder {
   color: ${color.grey_darker};
   font-family: museo-sans, sans-serif;
   font-size: 1rem;
  }
  &:focus {
    ::-webkit-input-placeholder { color:transparent; }
    ::-moz-placeholder { color:transparent; }
    border: 1px solid ${color.blue_darker};
  }
 }

 & .react-autosuggest__input:hover{
  transition: border 0.5s ease-in-out;
  border: 1px solid ${color.blue_darker};
 }
& .react-autosuggest__suggestions-container--open {
  display: ${props => props.isOpenSuggestionList ? 'block' : 'none'};
  position: absolute;
  margin-top: 1.125rem;
  width: ${props => props.widthSize ? props.widthSize : '40.9375rem'};
  color: ${color.grey_29_percent};
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: -0.05px;
  z-index: 1111;
  animation-name: fade;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  };
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 12.875rem;
  }
  height: 14.0625rem;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3.125rem rgba(1,47,87,0.1);
  -ms-box-shadow: 0px 0px 3.125rem rgba(1,47,87,0.1);
  border-radius: 0.625rem;
  & ::before {
    content: "";
    position: absolute;
    left: 20rem;
    @media screen and (max-width: 690px) {
      left: 45vw;
    }
    top: -1.25rem;
    width: 0;
    height: 0;
    border-top: 0.9375rem solid transparent;
    border-right: 0.9375rem solid #fff;
    border-bottom: 0.875rem solid transparent;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

& .react-autosuggest__suggestions-list {
  margin: 0;
  width: calc(100% - 1.75rem - 0.625rem);
  height: 8.3125rem;
  padding: 1.125rem 0 0 1.75rem;
  list-style-type: none;
  font-weight: 300;
  font-size: 1rem;
  line-height: .625rem;
  overflow: auto;
  margin-top: 0.875rem;
  pointer-events: ${props => props.pointerEvent ? 'auto' : 'none'};
  ${ScrollBar}
  @media screen and (max-width: 690px) {
    width: auto;
    margin-right: 1.1875rem;
    padding-left: 1.1875rem;
    margin-top: 1.375rem;
    height: 8.125rem;
    padding-top: 0.8125rem;
  }
}

& .react-autosuggest__suggestion {
  font-family: museo-sans, sans-serif;
  cursor: pointer;
  margin-bottom: 1.6rem;
  @media screen and (max-width: 690px) {
    margin-bottom: 1.875rem;
  }
}

& .react-autosuggest__suggestion:hover {
  font-family: museo-sans, sans-serif;
  color: ${color.blue_17_percent};
  cursor: pointer;
  font-weight: bold;
}

& .react-autosuggest__suggestion-match {
  font-family: museo-sans, sans-serif;
  font-weight: bold;
}
`

const StyledItalic = styled.i`
  font-size: 1rem;
  color: ${color.grey_darker};
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: normal;
`

const SuggestionContents = styled.span`
  &:focus {
    color: ${color.blue_17_percent};
    font-weight: bold;
  }
`

const StyledEditButton = styled.button`
  background: transparent;
  padding: 0;
  color: inherit;
  border: none;
  text-decoration: underline;
  cursor: pointer;
`

type Ref = HTMLInputElement

const EmployerAutosuggestField = forwardRef<Ref, Props>((props: any, aRef) => {
  const { inputFromProps, isValidatedFromProp, onSelectedItemProp,
    handleData, suggestionSelectedOrTyped,
    isAvailableToInteract, handlePayrollDeductionRequire } = props

  const [inputValue, setValue] = useState('')
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [inputSuccess, setInputSuccess] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [visibility, setVisibility] = useState(false)
  const [validationMsg, setValidationMsg] = useState<React.ReactNode>('')
  const [disableClickOnSuggestion, setDisableClickOnSuggestion] = useState(true)
  const [labelIconState, setLabelIconStateState] = useState(true)
  const [employerSelectedOrTyped, setEmployerSelectedOrTyped] = useState<any>(undefined)
  const [onNext, setOnNext] = useState(0)
  const [menuOpenForTab, setMenuOpenForTab] = useState(false)
  const [tab, setTab] = useState(false)
  const formData = useSelector((state: RootState) => state.FormHighOrder.pageData[0].data)

  const noResultString = 'No results found.'
  const suggestString = 'Continue typing and press enter to manually add your employer.'

  useEffect(() => {
    inputFromProps ? setValue(inputFromProps) : {}
    isValidatedFromProp ? setInputSuccess(isValidatedFromProp) : {}
    isValidatedFromProp ? setVisibility(isValidatedFromProp) : {}
    onSelectedItemProp ? onSelectedItemProp(isValidatedFromProp) : {}
    if (inputFromProps === undefined) {
      setValue('')
      setInputSuccess(false)
      setVisibility(false)
    }
  }, [inputFromProps, isValidatedFromProp])

  const EditButton: React.FC<React.ComponentProps<typeof StyledEditButton>> = useMemo(() => {
    const onClick = () => {
      const input: React.RefObject<HTMLInputElement> = aRef as React.RefObject<HTMLInputElement>

      if (input && input.current) {
        input.current.focus()
        input.current.select()
      }
    }

    return function BoundEditButton(props) {
      return (
        <StyledEditButton
          { ...props }
          onClick={onClick}
        />
      )
    }
  }, [aRef])

  useEffect(() => {
    if (formData && inputSuccess && !validationMsg) {
      const { employer, suburbWork, stateFromSuburb, workPostcode } = formData

      setValidationMsg(
        <>
          Great! You work at
          {' '}
          {employer.value}
          {' '}
          {workPostcode?.value ? stateFromSuburb?.value ? stateFromSuburb.value : workPostcode.value : suburbWork.value}
          , click to continue or
          {' '}
          <EditButton>update your details</EditButton>
        </>
      )
    }
  }, [inputSuccess, formData])

  useEffect(() => {
    if (!inputSuccess) {
      setValidationMsg('')
    }
  }, [inputSuccess])

  useEffect(() => {
    if (inputSuccess === false && inputValue.length > 1) {
      setMenuOpenForTab(true)
    }
    else {
      setMenuOpenForTab(false)
      setIsMenuOpen(false)
    }
  }, [inputSuccess, inputValue])

  useEffect(() => {
    if (visibility === true && inputSuccess === false) {
      setLabelIconStateState(false)
    } else {
      setLabelIconStateState(true)
    }
  }, [inputSuccess, visibility])

  useEffect(() => {
    suggestionSelectedOrTyped ? suggestionSelectedOrTyped(employerSelectedOrTyped) : {}
  }, [employerSelectedOrTyped])

  useEffect(() => {
    const closeExpand = (_) => {
      setTab(false)
      setIsMenuOpen(false)
    }

    document.body.addEventListener('click', closeExpand)
    return () => { document.body.removeEventListener('click', closeExpand) }

  }, [])

  const getStateFromForm1 = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  // check that the user hasn't entered only whitespace
  const whitespaceRegex = /^\s*$/ig

  const inputProps = {
    placeholder: 'Type your employer here',
    name: 'employer',
    value: inputValue,
    onChange: (event, { newValue }) => {
      setValue(newValue)
      setInputSuccess(false)
      setEmployerSelectedOrTyped('typed')
    },
    onKeyDown: (event: any) => {
      if (event.key === 'Tab') {
        setTab(true)
      }
    },
    onBlur: (event: any) => {
      if (tab === false) {
        setVisibility(true)
        if (event.target !== null) {
          handleData ? handleData(event.target.value, !!event.target.value && Boolean(event.target.value.match(whitespaceRegex) === null)) : {}
        }
      }
    },
    id: 'employer-search',
    autoComplete: 'none',
    autoCorrect: 'off',
    ref: aRef
  }

  const renderSuggestionsContainer = (params: RenderSuggestionsContainerParams) => {
    return (
      <div {...params.containerProps}>
        {params.children}
        {
          isMenuOpen && (
            <DropdownFooter>
              <DropdownFooterContent>Can’t find your employer? Continue typing</DropdownFooterContent>
            </DropdownFooter>
          )}
      </div>
    )
  }

  const arrayRefs: HTMLDivElement[] = []

  const handleEmployerValue = (suggestionValue: string) => {
    const isPayrollDeductionRequired = getIsPayrollDeductionRequired(suggestionValue, getStateFromForm1)
    handlePayrollDeductionRequire ? handlePayrollDeductionRequire(isPayrollDeductionRequired) : {}
    const isValid = suggestionValue !== undefined
      && suggestionValue.length > 0
      && suggestionValue !== noResultString
    setInputSuccess(isValid)
    onSelectedItemProp ? onSelectedItemProp(isValid) : {}
    handleData ? handleData(suggestionValue, isValid) : {}
    setEmployerSelectedOrTyped('selected')
  }

  const renderFoundResults = (suggestion: string): React.ReactElement => {

    const matches = match(suggestion, inputValue)
    const parts = parse(suggestion, matches)

    return (
      <SuggestionContents
        tabIndex={0}
        style={{ lineHeight: '1' }}
        onKeyDown={(e: any) => {
          if (e.key === 'ArrowDown') {
            setOnNext((onNext + 1))
            arrayRefs[(onNext + 1)]?.focus()
          }
          if (e.key === 'ArrowUp') {
            setOnNext((onNext - 1))
            arrayRefs[(onNext - 1)]?.focus()
          }
          if (e.key === 'Enter') {
            e.preventDefault()
            handleEmployerValue(e.target.textContent)
          }
        }}
        ref={(ref: any) => { arrayRefs.push(ref) }}
      >
        {parts.map((part: { text: string; highlight: boolean }, index: number) => {
          const className = part?.highlight ? 'react-autosuggest__suggestion-match' : ''

          return (
            <span
              className={className}
              key={index}>
              {part.text}
            </span>
          )
        })}
      </SuggestionContents>
    )
  }

  return (
    <>
      <Label>
        Who do you work for?
        {' '}
        <LabelIcon iconStates={labelIconState}>
          {'  '}
        *
        </LabelIcon>
      </Label>
      <StyledAutosuggestWrapper
        onHoverInputSuccess={inputSuccess}
        isVisible={props.isVisible ? props.isVisible(visibility) : {}}
        pointerEvent={disableClickOnSuggestion}
        isOpenSuggestionList={isMenuOpen}
        className=""
      >
        <AutoSuggest
          suggestions={suggestions}
          focusInputOnSuggestionClick={false}
          alwaysRenderSuggestions={menuOpenForTab}
          onSuggestionsClearRequested={() => {
            if (inputSuccess === true) setIsMenuOpen(false)
          }}
          onSuggestionsFetchRequested={({ value }) => {
            const valueGetter = getSuggestions(value, getStateFromForm1)
            valueGetter.length > 0 ? setSuggestions(valueGetter) : setSuggestions([noResultString])
            setIsMenuOpen(true)
          }}
          onSuggestionSelected={(_, { suggestionValue }) => {
            handleEmployerValue(suggestionValue)
          }}
          shouldRenderSuggestions={(value) => {
            return suggestionRule(value)
          }}
          getSuggestionValue={suggestion => suggestion}
          renderSuggestion={suggestion => {
            if (suggestion !== noResultString) {
              // Can't update component state during render function
              setDisableClickOnSuggestion(true)
              return renderFoundResults(suggestion)
            }
            else {
              setDisableClickOnSuggestion(false)
              return (
                <span>
                  <StyledItalic>
                    {noResultString}
                    <br />
                    {suggestString}
                  </StyledItalic>
                </span>
              )
            }
          }}
          inputProps={inputProps}
          renderSuggestionsContainer={renderSuggestionsContainer}
          renderInputComponent={(inputProps) => (
            // @ts-ignore
            <Input
              {...inputProps}
              tabIndex={isAvailableToInteract ? 0 : -1}
              onClick={() => inputValue.length > 1 && setIsMenuOpen(true)}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  handleData ? handleData(event.target.value, event.target.value.match(whitespaceRegex) === null && !!event.target.value) : {}
                  setIsMenuOpen(false)
                  onSelectedItemProp ? onSelectedItemProp(!!event.target.value) : {}
                  setInputSuccess(event.target.value.match(whitespaceRegex) === null && !!event.target.value)
                  setVisibility(true)
                }
              }} />
          )}
        />
      </StyledAutosuggestWrapper>
      <ValidationIndicator
        isCorrect={inputSuccess}
        {...{ message: validationMsg, visibility }} />
    </>
  )
})

EmployerAutosuggestField.displayName = 'EmployerAutosuggestField'

export default EmployerAutosuggestField
