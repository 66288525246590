import React from 'react'
import styled from 'styled-components'

const Container = styled.div`padding-top: 2.5rem`

export const ContactOption: React.FC = () => {
  return (
    <Container>
      <div>
        If you&apos;d like to pay via Credit Card or Direct Debit, the SDA will give you a call to verify your payment details.
      </div>
      <br/>
      <div>
        Please note there may be a delay over the Christmas and New Year period. We will contact you as soon as we can.  If you need to get in touch with our team, please contact us on 1300 152 851
      </div>
    </Container>
  )
}

