import React from 'react'
import styled from 'styled-components'
import ButtonItem from './ItemButton'
import Label from '../Label'
import ValidationIndicator from '../ValidationIndicator'
import color from '../color-css/color'

const Container = styled.div`
  margin-top: 2rem;
`

const ButtonsContainer = styled.div`
  @media screen and (max-width: 690px) {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 0.625rem;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
`

const ContainerInfo = styled.div`
  display: flex,
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`

const LabelIcon = styled.span`
  color: ${color.blue_darker};
`

interface Props {
  label: string
  required?: boolean
  itemLabels: string[]
  activeIndex?: number
  onSelected: (index: number) => void
}

export const GenericCheckboxButtonGroup: React.FC<Props> = ({
  label,
  required,
  itemLabels,
  activeIndex,
  onSelected,
}) => {

  const optionIsSelected = activeIndex != undefined
  
  return (
    <Container>
      <Label>
        {label}
        {required && <LabelIcon>*</LabelIcon>}
      </Label>
      <ButtonsContainer>
        {itemLabels.map((itemLabel, itemIndex) => {
          return <ButtonItem
              key={`${itemLabel}-${itemIndex}`}
              active={activeIndex == itemIndex}
              title={itemLabel}
              onPress={() => {
                onSelected(itemIndex)
              }} />
          
        })}
      </ButtonsContainer>
      <ContainerInfo>
        <ValidationIndicator
          visibility={optionIsSelected}
          isCorrect={optionIsSelected} 
        />

      </ContainerInfo>
    </Container>
  )
}

export default GenericCheckboxButtonGroup
