export default (branch, copyKey, paymentType) => {

  if (typeof branch === 'undefined') {
    branch = 'SA'
  }

  const universalMembershipAgreementCopy = `I HEREBY apply for membership of the Shop, Distributive and Allied Employees Association, as registered under the Fair Work (Registered Organisations) Act 2009 (Cth) and (if eligible) any relevant state counterpart union that exists in my location. I agree to comply with and be bound by the Rules of each Association (SDA) as amended from time to time.\n
  I authorise the SDA to use my personal information in order to communicate with me, for direct marketing, research and member surveys. I acknowledge the SDA is affiliated to the Australian Council of Trade Unions (ACTU) and local branches of the ACTU, the Australian Labor Party and Union Network International. Privacy Act 1998 - The SDA is bound by the Privacy Act. This information is collected to enable the SDA to contact you about matters relating to your SDA membership, and to ensure that we have the necessary information to represent your employment and related interests. A copy of the SDA’s Privacy Statement is available from SDA offices and our website.`
  const universalPayrollCopy = 'I authorise my employer to deduct from my wages the membership contributions as prescribed by the SDA as varied from time to time, and pay those contributions on my behalf to the relevant SDA Branch. The deductions shall commence from the first pay period after the employer is in receipt of the authorisation and occur on each pay period thereafter. If details of the personal information I have provided in this application form change, I authorise my employer to provide the SDA with details of the changes.'

  const SACopy = {
    'preExistingIssues': 'Please be aware that we don’t automatically provide assistance to workers who have an issue when they join. If this is you, after you join, you will need to contact us to seek approval for assistance with that issue.',
    'membershipAgreement': universalMembershipAgreementCopy,
    'paymentAuthority': {
      'creditCard': 'I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union South Australia has deemed payable.',
      'directDebit': 'I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union South Australia has deemed payable.'
    },
    'paymentAgreement': {
      'payroll': universalPayrollCopy,
      'creditCard': 'By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>',
      'directDebit': 'By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>'
    },
    'joinSuccess': {
      'rows': [
        {
          'col1': {
            'html': '<h2>Congratulations! You’re now a member of one of Australia’s largest unions.</h2><p>In the next few days you will receive an email with access to the Members Portal, followed by a Welcome Pack in the mail.</p><p>Remember, if you need help with a workplace issue, you can always call us on <a href="tel:0881391000">8139 1000</a>. We are here to answer all your workplace queries, no matter how big or small.</p>'
          },
          'col2': {
            'html': '<img src=\'/SA-Application-Complete-Image1.jpg\'/>',
            'fullWidth': true
          }
        },
        {
          'col1': {
            'html': '<img src=\'/SA-Application-Complete-Image2.jpg\'/>',
            'fullWidth': true
          },
          'col2': {
            'html': '<h2>Remember...</h2><p>SDA membership is the best workplace insurance you can have!</p>'
          }
        }
      ]
    },
    'notesOnPayment': [
      'Your membership can be cancelled at any time',
      'Membership fees are tax-deductible',
      'Transactions will appear on your statement as ‘SDA UNION’'
    ],
    'notesOnPaymentPayroll': [
      'Membership fees will be automatically deducted from your pay and will appear on your payslip',
      'If you don’t work any hours that pay cycle, you won’t be charged any fees',
      'Your membership can be cancelled at any time',
      'Membership fees are tax-deductible',
    ]
  }

  const branchCopy = {
    'SA': SACopy,
    'NT': SACopy,
    'Broken Hill': SACopy,
    'WA': {
      'preExistingIssues':
            `Please be aware that the SDA will not assist with issues which commenced prior to joining. I understand that the SDA is not able to assist me with any pre-existing issues
            `,
      'membershipAgreement': universalMembershipAgreementCopy,
      'paymentAuthority': {
        'creditCard': `We use Paystream to securely process credit card payments. The Paystream is Level 1 PCI DSS Compliant payment gateway provider, which mean they adhere to the highest industry security standards when handling credit card information.

                Upon choosing direct debit as my payment method, I Confirm that the details I give are true and correct. I request and authorise The Shop, Distributive and Allied Employees Association of Western Australia (User ID 069367) to debit my nominated credit card each month. I understand that my direct debit request and membership will remain in force until cancelled in writing by me. I will advise the union of any changes to my credit card and contact details.
                `,
        'directDebit': 'Upon choosing direct debit as my payment method, I Confirm that the details I give are true and correct. I request and authorise The Shop, Distributive and Allied Employees Association of Western Australia (User ID 069367) to debit my nominated account each month. I understand that my direct debit request and membership will remain in force until cancelled in writing by me. I will advise the union of any changes to my account and contact details.'
      },
      'paymentAgreement': {
        'payroll': universalPayrollCopy,
        'creditCard': 'I understand and agree to the <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DDRServiceAgreement-WA.pdf">terms and conditions</a> set out in this Direct Debit Request and of the Direct Debit Request Service Agreement.',
        'directDebit': 'I understand and agree to the <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DDRServiceAgreement-WA.pdf">terms and conditions</a> set out in this Direct Debit Request and of the Direct Debit Request Service Agreement.'
      },
      'joinSuccess': {
        'rows': [
          {
            'col1': {
              'html': '<h2>Welcome to the SDA</h2><p>By joining your Union you have added your voice to the call for a fair go at work.</p></br><p>Please take some time to check out our website and familiarize yourself with all of the benefits your SDA membership provides.</p>'
            },
            'col2': {
              'html': '<img src=\'/form_success_1.png\'/>',
              'fullWidth': true
            }
          },
          {
            'col1': {
              'html': '<img src=\'/form_success_2.png\'/>',
              'fullWidth': true
            },
            'col2': {
              'html': '<p>Remember, the SDA is here to serve you.  If you have any queries about your rights at work or something that is happening in your workplace, call us on:</p></br><h3>1300 ASK SDA (1300 275 732)</h3>'
            }
          }
        ]
      },
      'notesOnPayment': [
        'Your membership can be cancelled at any time',
        'Membership fees are tax-deductible',
      ],
      'notesOnPaymentPayroll': [
        'Membership fees will be automatically deducted from your pay and will appear on your payslip',
        'If you don’t work any hours that pay cycle, you won’t be charged any fees',
        'Your membership can be cancelled at any time',
        'Membership fees are tax-deductible',
        'Transactions will appear on your statement as ‘SDA UNION’'
      ]
    },
    'TAS': {
      'preExistingIssues': 'Any help given by the SDA cannot pre-date my membership and that while the Union can still offer me advice, it cannot assist me with pre-existing problems.',
      'membershipAgreement': universalMembershipAgreementCopy,
      'paymentAuthority': {
        'creditCard': 'I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union Tasmania has deemed payable.',
        'directDebit': 'I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union Tasmania has deemed payable.'
      },
      'paymentAgreement': {
        'payroll': universalPayrollCopy,
        'creditCard': 'By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>',
        'directDebit': 'By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>'
      },
      'joinSuccess': {
        'rows': [
          {
            'col1': {
              'html': '<h2>Welcome to the SDA!</h2><p>If you chose payroll deduction as your payment method, soon you will receive an information pack and membership card. However, you can take advantage of our services and benefits immediately. If you have a question, contact us – we have all the answers.</p><p>If you opted to receive a call, we\'ll call you soon to confirm your direct debit or credit card details to complete your application.</p>'
            },
            'col2': {
              'html': '<img src=\'/Dylan Brooks.jpg\'/>',
              'fullWidth': true
            }
          },
          {
            'col1': {
              'html': '<img src=\'/IMG_1861.jpg\'/>',
              'fullWidth': true
            },
            'col2': {
              'html': `<p>There are many ways that we will communicate with you – through our website, regular emails and social media, even the SDA notice board or a visit to your workplace. We like to hear from you too either by phone, email or messaging through Social Media.</p>
                            <p>
                                <a href="https://www.sdatas.asn.au/member-benefits/" target="_blank">https://www.sdatas.asn.au/member-benefits/</a>
                                <br/>Contact number: <a href="tel:1300 152 851">1300 152 851</a>
                                <br/><a href="mailto:secretary@sdatas.asn.au">secretary@sdatas.asn.au</a>
                            </p>`
            }
          }
        ]
      },
      'notesOnPayment': undefined,
      'notesOnPaymentPayroll': undefined
    },
    'QLD': {
      'preExistingIssues': 'I understand that on receipt of this ‘electronic’ information, the SDA may contact me by phone, email or letter requesting further details that will determine my eligibility for membership. I further understand that the SDA can only represent my interests from the date that membership is accepted. I also note that under no circumstances will the SDA become involved in issues or disputes occurring before that date.',
      'membershipAgreement': `Please enrol me as a member of the Shop Distributive and Allied Employees’ Association (Association) and as a member of the Shop Distributive and Allied Employees’ Association (Queensland Branch), Union of Employees (Union).  I pledge myself to comply with the Rules of the Association and of the Branch to which I am attached and of the Union and any amendments or additions duly made to such Rules in accordance with the Rules.  I understand that the personal information contained on this application may be used for other purposes including but not limited to, research and surveys and the provision of direct marketing offers for products and services provided by or on behalf of the SDA (or other organisations) considered to be of interest to members. \n
        The Certified Rules of each Branch of the Shop, Distributive and Allied Employees’ Association, provide for the financial obligations arising from membership and the circumstances and the manner in which a member may resign.  These Rules are available for inspection at the Office of the Industrial Registrar.  PLEASE NOTE - When terminating membership, you MUST notify the Union Office.`,
      'paymentAuthority': {
        'creditCard': 'I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union Queensland has deemed payable.',
        'directDebit': 'I request and authorise Fat Zebra ACN 154 014 785, APCA User ID Number 502574 to arrange, through its own financial institution, a debit to the nominated account below any amount SDA Union Queensland has deemed payable.'
      },
      'paymentAgreement': {
        'creditCard': 'By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>',
        'directDebit': 'By signing this Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements set out in this Request and in your <a target="_blank" href="https://storage.googleapis.com/sda-production-email-assets/SDA-DirectDebitServiceAgreement.pdf">Direct Debit Request Service Agreement</a>',
        'payroll': `I hereby authorise my employer to make a deduction from my pay on each occasion commencing from my first pay period and to continue to do so at each pay period thereafter and remit same in accordance with the joint direction of the Secretary of the Branch and of the Union or failing agreement between them, as directed by the National President of the Association.  This authority shall remain in force so long as I remain an eligible employee of the Company.\n
          In order to meet my financial commitment as a member of the Association, and the Branch to which I am attached and the Union, I hereby authorize the employer to deduct membership fees from my wages in accordance with the association’s schedule of fees, as varied from time to time, and to pay the monies so deducted on my behalf to the relevant SDA Branch. I authorise my employer to supply the SDA with updated personal information on the membership details set out in my SDA membership application form. These authorities shall commence from the first pay period after the employer is in receipt of the authorisation, occur on each pay period thereafter and remain in force as long as I remain an eligible employee of the employer, or should I revoke this authorisation.`
      },
      'joinSuccess': {
        'rows': [
          {
            'col1': {
              'html': '<h2>Congratulations!</h2><p>You’re now a member of one of Australia’s largest unions, joining over 220,000 other members Australia wide. As an SDA member, you’re covered when issues or problems arise at work. You\'re also helping improve pay and conditions in your workplace. We\'ll be in touch with your membership details soon.</p>'
            },
            'col2': {
              'html': '<img src=\'/form_success_1.png\'/>',
              'fullWidth': true
            }
          },
          {
            'col1': {
              'html': '<img src=\'/form_success_2.png\'/>',
              'fullWidth': true
            },
            'col2': {
              'html': '<p>The SDA is at work with you and membership is the best workplace insurance you can have! Feel free to contact us on 07 3833 9500 for confidential advice and assistance. </p>'
            }
          }
        ]
      },
      'notesOnPayment': [
        'Our website is safe and your payment details will be securely processed. If you have any concerns, please contact the SDA office on 07 3833 9500.'
      ],
      'notesOnPaymentPayroll': [
        'Membership fees will be automatically deducted from your pay and will appear on your payslip',
        'If you don’t work any hours that pay cycle, you won’t be charged any fees',
        'Our website is safe and your payment details will be securely processed. If you have any concerns, please contact the SDA office on 07 3833 9500.'
      ]
    },
    'Newcastle': {
      'preExistingIssues': 'Any help given by the SDA cannot pre-date my membership and that while the union can still offer me advice, it can’t assist me with pre-existing problems.',
      'membershipAgreement': universalMembershipAgreementCopy,
      'paymentAuthority': {
        'creditCard': undefined,
        'directDebit': undefined
      },
      'paymentAgreement': {
        'creditCard': undefined,
        'directDebit': undefined,
        'payroll': undefined
      },
      'joinSuccess': {
        'rows': [
          {
            'col1': {
              'html': '<h2>Content not provided</h2>'
            },
            'col2': {
              'html': '<img src=\'/form_success_1.png\'/>',
              'fullWidth': true
            }
          },
          {
            'col1': {
              'html': '<img src=\'/form_success_2.png\'/>',
              'fullWidth': true
            },
            'col2': {
              'html': '<h2>Content not provided</h2>'
            }
          }
        ]
      },
      'notesOnPayment': undefined,
      'notesOnPaymentPayroll': undefined
    }
  }

  if (paymentType) {
    return branchCopy?.[branch]?.[copyKey]?.[paymentType]
  } else {
    return branchCopy?.[branch]?.[copyKey]
  }
}
